<template>
    <div>
        <el-dialog ref="dialog" class="year-select-dialog" :visible.sync="visible" :close-on-click-modal="false"
            :close-on-press-escape="!hideClose" :show-close="!hideClose" :top=top width="564px" title="请选择案卷"
           >

            <div slot="title" class="year-head flex jc_b">
                <div class="title">请选择案卷</div>
                <t-btn fs="14px" @click="visible = false" v-if="!hideClose" isText>取消</t-btn>
                <t-btn fs="14px" @click="quit" type="danger" isText>退出阅卷</t-btn>
            </div>

            <div class="year-tab flex wrap">
                <div class="item" :class="{ on: activeId === item.id }" v-for="item in titleList"
                    @click="titleClick(item.id)" :key="item.id">
                    {{ item.name }}
                </div>
            </div>
            <t-pagination :pages="pages" @currentChange="currentChange" @sizeChange="sizeChange">
                <div class="content" slot="table" v-loading="isLoading">
                    <template v-if="archList && archList.length">
                        <div class="content-table">
                            <div class="content-head">
                                <div class="content-title flex jc_b center">
                                    <div class="name">文件夹名称</div>
                                    <div class="checked">操作</div>
                                </div>
                            </div>
                            <div class="content-body scroll-list"> 
                                <!-- <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto"> -->
                                <div class="content-item  flex jc_b center " @click="archItemClick(item)"
                                    v-for="(item, index) in archList" :key="index">
                                    <div class="name">{{ item.name }}</div>
                                    <div class="checked">选择</div>
                                </div>
                                <!-- </ul> -->

                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="nothing-box">
                            <i class="not-icon " />
                            <p class="not-tit">暂无案卷</p>
                        </div>
                    </template>
                </div>
            </t-pagination>
            <!-- <div class="flex jc_e ai_c pt20">
                <t-btn @click="confirm" w="78px" h="36px" class="bold ml30">确认</t-btn>
            </div> -->
        </el-dialog>
    </div> 
</template>
<script>
import pagination from '@/mixins/pagination'
export default {
    mixins: [pagination],
    data() {
        return {
            visible: false,
            archList: [],
            titleList: [],
            isLoading: true,
            activeId: "",
            hideClose: true,
            top: "30px",

        };
    },
    watch: {
        visible: {
            handler(newValue) {
                if (newValue) {
                    this.getPapersList();
                }
            },
            immediate: true,
        },
    },
    methods: {
        load() {
            this.count += 2
        },
        titleClick(id) {
            if (id === this.activeId) {
                return;
            }
            this.getPapersList(id);
        },
        archItemClick(item) {
            this.visible = false;
            this.$emit("archives-confirm", item.id);

            // this.archList = this.archList.map((it) => {
            //     it.checked = false;
            //     return it;
            // });
        },
        confirm() {
            let arr = this.archList.filter((it) => it.checked);
            if (arr.length > 0) {
                this.visible = false;
                this.$emit("archives-confirm", arr[0].id);
            }
        },
        // 显示
        show(id) {
            this.getPapersList(id);
            // this.hideClose = hideClose
            this.visible = true;
        },
        getPapersList(id = null) {
            let page = this.pageParams
            if (!id) {
                page.perPage = 6;
            }
            this.$api.papers.getPapersList({
                ...page,
                id
            }).then((res) => {
                // console.log("getPapersList===", res);
                this.isLoading = false;
                this.activeId = id || res.parent[0].id;
                if (!id) {
                    this.pages = Object.assign({}, res.page, {
                        layout: "prev, pager, next, jumper"
                    })
                }
                if (res.parent && res.parent.length > 0) {
                    this.titleList = res.parent
                }
                // if (this.titleList && this.titleList.length) {
                // }
                // if (!this.titleList || !this.titleList.length) {
                //   this.titleList = res.parent
                // }
                this.archList = res.items.map((it) => {
                    it.checked = false;
                    return it;
                });
                this.$nextTick(() => {
                    try {
                        let vH = document.body.clientHeight;
                        let dH = this.$refs.dialog.$el.querySelector(".el-dialog").clientHeight;
                        if (vH && dH && vH > dH) {
                            let offset = vH - dH;
                            if (offset > 60) {
                                console.log(vH, dH)
                                this.top = offset / 2 + "px"
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
            });
        },

        /**
         * @description 分页参数变化列表回调，调用分页方法
         */
        getList() {
            this.getPapersList();
        },
        // 退出系统
        quit() {
            // this.$router.go(-1)
            this.$router.push({
                name: "home"
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.scroll-list {
    width: 100%;
    height: 500px;
    overflow: scroll;
}

.content {
    width: 100%;

    &-item {
        padding: 10px 16px;
        cursor: pointer;

        &:hover {
            background-color: #F5F7FA;

            .name {
                color: #006AFF;
            }
        }

        .name {
            flex: 1;
            font-weight: 500;
            color: #60707D;
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
        }

        .checked {
            width: 50px;
            text-align: right;
            color: #006AFF;
        }
    }

    &-title {
        background: #F4F7FA;
        border-radius: 8px 8px 0px 0px;
        padding: 10px 16px;

        .name {
            flex: 1;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #383D41;
            line-height: 20px;
        }

        .checked {
            width: 50px;
            text-align: right;
        }
    }

    &-body {
        max-height: 800px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.year-select-dialog {
    >div {
        max-height: calc(100vh - 60px);
        overflow: auto;
    }

    ::v-deep .el-dialog__header {
        padding: 22px 24px;
    }

    ::v-deep .el-dialog__body {
        padding: 14px 24px 24px;
    }

    .year-tab {
        .item {
            width: 78px;
            height: 32px;
            background: rgba(0, 106, 255, 0.05);
            border: 1px solid #D1E4FF;
            text-align: center;
            font-weight: 500;
            color: #006AFF;
            font-size: 12px;
            border-radius: 16px;
            line-height: 30px;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;

            &:not(:first-child) {
                margin-left: 8px;
            }

            &.on,
            &:hover {
                background: #006AFF;
                border-color: #006AFF;
                color: #fff;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-top: 5px solid #006AFF;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                }
            }
        }
    }

    .year-head {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 23px 24px 13px;

        .title {
            color: #111111;
            font-size: 16px;
        }
    }
}
</style>
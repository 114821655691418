<template>
    <el-dialog ref="dialog" class="pdf-select-dialog" :visible.sync="visible" :close-on-click-modal="false" :top=top
        width="626px">
        <template v-slot:title>
            <span style="font-size: 18px;font-weight: 600;line-height: 21px;color: #111111;margin-right:15px">打开案卷</span>
            <!-- <span style="font-size: 14px;color: #999999;">* 打开下一个案卷前，请先点击“关闭当前案卷”按钮</span> -->
        </template>
        <div class="file-tab">
            <div class="table-head flex">
                <div class="td" :class="{ 'active': selectedIndex == 0 }" @click="selectedIndex = 0">
                    <p>最近打开的PDF</p>
                    <p class="line"></p>
                </div>
                <div class="td" :class="{ 'active': selectedIndex == 1 }" @click="selectedIndex = 1">
                    <p>案卷名称</p>
                    <p class="line"></p>
                </div>
            </div>
            <div class="table-body">
                <div class="table-th flex jc_b center">
                    <div class="td">
                        <p>文件名称</p>
                    </div>
                    <div class="td">
                        <p>操作</p>
                    </div>
                </div>
                <template v-if="selectedIndex == 0">
                    <template v-if="latelyFieles && latelyFieles.length">
                        <div class="table-tr flex jc_b center" v-for="(v, i) in latelyFieles" @click="select(i, 0, v)"
                            :key="i">
                            <div class="td">
                                <p>{{ v.name }}</p>
                            </div>
                            <div class="td">
                                <p>选择</p>
                            </div>
                        </div>
                    </template>


                    <div v-else class="nothing-box">
                        <i class="not-icon" />
                        <p class="not-tit">最近没有打开的案卷</p>
                    </div>
                </template>
                <template v-else-if="selectedIndex == 1">
                    <t-pagination :pages="pdfPageParam" @currentChange="currentChange">

                        <div class="content" slot="table">
                            <template v-if="pdfFiles && pdfFiles.length">
                                <div class="table-tr flex jc_b center" v-for="(v, i) in pdfFiles" @click="select(i, 1, v)"
                                    :key="i">
                                    <div class="td">
                                        <p>{{ v.name }}</p>
                                    </div>
                                    <div class="td">
                                        <p>选择 </p>
                                    </div>
                                </div>
                            </template>
                            <div v-else class="nothing-box">
                                <i class="not-icon" />
                                <p class="not-tit">暂无PDF</p>
                            </div>
                        </div>

                    </t-pagination>
                </template>

            </div>
        </div>
        <!-- <div class="file-table" style="margin-bottom : 10px">
            <div class="table-th">
                <div class="td f-1">
                    <span>最近打开的PDF</span>
                </div>
            </div>
            <template v-if="latelyFieles && latelyFieles.length">
                <div
                    v-for="(v, i) in latelyFieles" 
                    @click="select(i,0,v)"
                    :key="i" 
                    class="table-tr pointer not-select"
                    :class="{'active':selectedIndex=='0-'+i}">
                    <div class="td f-1">
                        <span>{{ v.name }}</span>
                    </div>
                </div>
            </template>
            <div v-else class="table-tr">
                <div class="td td-nothing f-1">
                    <span>最近没有打开的案卷</span>
                </div>
            </div>
        </div>
        <t-pagination
            :pages="pdfPageParam"
            @currentChange="currentChange">
            <div class="content" slot="table">
                <div class="file-table">
                    <div class="table-th">
                        <div class="td f-1">
                            <span>案卷名称</span>
                        </div>
                    </div>
                    <template v-if="pdfFiles && pdfFiles.length">
                        <div
                            v-for="(v, i) in pdfFiles" 
                            @click="select(i,1,v)"
                            :key="i" 
                            class="table-tr pointer not-select"
                            :class="{'active':selectedIndex=='1-'+i}">
                            <div class="td f-1">
                                <span>{{ v.name }}</span>
                            </div>
                        </div>
                    </template>
                    <div v-else class="table-tr">
                        <div class="td td-nothing f-1">
                            <span>暂无PDF</span>
                        </div>
                    </div>
                </div>
            </div>
        </t-pagination>
       
		<div class="flex jc_e ai_c pt20">
            <t-btn @click="visible = false" class="bold" isText>取消</t-btn>
            <t-btn @click="commit" w="78px" h="36px" class="bold ml30">确认</t-btn>
        </div> -->
    </el-dialog>
</template>

<script>
import message from '@/mixins/message'
export default {
    mixins: [message],
    props: {
        pdfFiles: {
            type: Array,
            default: () => []
        },
        latelyFieles: {
            type: Array,
            default: () => []
        },
        pdfPageParam: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            visible: false,
            selectedIndex: 0,
            selectedItem: null,
            top: "30px"
        };
    },
    methods: {
        select(index, type, item) {
            if (!item.isFile) {
                this.$emit('isFile', item, );
            } else {
                this.selectedItem = item;
                this.commit()
            }

            // this.selectedIndex = type + "-" + index       
            // this.selectedItem = item;
        },
        // 确认
        commit() {
            const { selectedItem: item } = this;
            if (item) {
                let url = item.url;
                this.$emit('commit', item, url);
                this.selectedItem = null;
                this.selectedIndex = ""
            } else {
                this.$message.error("请选择要打开的案卷")
            }
        },
        // 显示
        show() {
            this.visible = true;
            this.$nextTick(() => {
                try {
                    let vH = document.body.clientHeight;
                    let dH = this.$refs.dialog.$el.querySelector(".el-dialog").clientHeight;
                    if (vH && dH && vH > dH) {
                        let offset = vH - dH;
                        if (offset > 60) {
                            this.top = offset / 2 + "px"
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        },

        /**
         * @description 分页参数变化列表回调，调用分页方法
         */
        currentChange(currentPage) {
            this.$emit("pageChange", currentPage);
        },
    }
};
</script>

<style lang="scss">
.pdf-select-dialog {
    >div {
        max-height: calc(100vh - 60px);
        overflow: auto;
    }
}
</style>

<style lang="scss" scoped>
.file-tab {
    .table-head {
        margin-bottom: 13px;

        .td {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            line-height: 16px;
            margin-right: 16px;
            cursor: pointer;

            .line {
                width: 18px;
                height: 4px;
                margin: 19px auto 0;
                background: transparent;
            }

            &.active {
                color: #006AFF;

                .line {
                    background: #F4A93D;
                }
            }
        }
    }

    .table-body {
        max-height: 800px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0;
        }

        .table-tr {
            padding: 10px 16px;
            cursor: pointer;

            >.td {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #006AFF;
                line-height: 20px;

                &:not(:last-child) {
                    color: #60707D;
                    max-width: 450px;
                }
            }

            &.active,
            &:hover {
                background: #F5F7FA;

                >.td {
                    &:not(:last-child) {
                        color: #006AFF;
                    }
                }
            }
        }

        .table-th {
            padding: 0 16px;
            height: 44px;
            background: #F4F7FA;
            border-radius: 16px 16px 0 0;

            >.td {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #383D41;
            }
        }
    }
}
</style>

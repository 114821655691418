<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :title="options.type === 'edit' ? '修改名称' : '创建名称'"
    top="40vh"
    width="440px"
  >
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-position="left"
      size="medium"
      hide-required-asterisk
      @submit.native.prevent
    >
      <el-form-item prop="name">
        <el-input
          v-model="params.name"
          class="input-width"
          placeholder="请填写名称"
        />
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
		<t-btn
			w="78px"
			h="36px"
			fs="14px"
			type="info"
			@click="visible = false" 
			class="bold">取消</t-btn>
		<t-btn
		  @click="commit"
		  w="78px"
		  h="36px"
		  fs="14px"
		  class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      options: {},
      params: {
        name: '',
      },
      rules: {
        name: [{ required: true, message: "请填写名称" }],
      },
    };
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { params, options } = this
          const name = params.name;
          this.visible = false;
          this.$emit("commit", { params: { name }, options });
        }
      });
    },
    // 显示
    show(options = {
      type: 'add'
    }) {
      if (options.name) {
        this.params.name = options.name
      }else{
        this.params.name = "指控事实";
      }
      this.options = options
      this.visible = true;
    },
    // 关闭重置
    closed() {
      this.options = {};
      this.$refs.form.resetFields();
    },
  },
};
</script>

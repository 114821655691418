<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="436px"
      title="保存文件"
      style="z-index: 2">
      <!-- <div v-if="hasOld" class="flex jc_c ai_c mb14 mt30">
         <t-btn
          @click="save(0)"
          class="mr46"
          w="120px"
          h="36px"
          >覆盖原文件</t-btn>
        <t-btn
          @click="save(1)"
          w="120px"
          h="36px"
          >存为新文件</t-btn>
      </div> -->
      <div class="flex jc_c ai_c mb14 mt30">
        <t-btn
          @click="save(1)"
          w="120px"
          h="36px"
          >确认保存</t-btn>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: ["cid"],
  data() {
    return {
      visible: false,
      hasOld: false     //是否已经保存过文件
    };
  },
  watch: {
    cid: {
      handler(cid){
        if( cid ) this.getHasOld();
      },
      immediate: true
    }
  },
  methods: {
    // 显示
    show() {
      this.visible = true;
      this.getHasOld();
    },
    save(type) {
      this.$emit('commit', type);
      // this.hasOld = true;
    },
    getHasOld(){
      if( this.cid ){
        this.$api.papers.getOldFileList({
          cid: this.cid,
        }).then(res => {
          this.hasOld = res.length > 0 
        })
      }
    }
  },
};
</script>
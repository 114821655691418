<template>
  <div class="marking-container" v-loading="isLoading" >
    <!-- 界面顶部操作栏 -->
    <div class="marking-header flex jc_b">
      <div><i class="el-icon-house" @click="back"></i>返回首页</div>
      <div class="marking-header-main">
        <div class="search-input-box">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="请输入关键词搜索"
            size="small"
            v-model="keyword"
          >
            <template slot="suffix">
              <!-- 如果要做前端搜索，搜索转换高亮前，保存一下正常的数据副本，取消时可以直接还原，保存的时候也直接用副本保存 -->
              <div v-if="inSearch" class="in-search-box">
                <div class="search-result-box">
                  <i
                    class="el-icon-caret-top"
                    :class="{ disabled: hightListActive !== 0 }"
                    @click="lastHightLight"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="{
                      disabled: hightListActive == hightListLength - 1,
                    }"
                    @click="nextHightLight"
                  ></i>
                </div>
                <span @click="cancelSearch">取消搜索</span>
              </div>
              <el-button
                v-else
                class="search-button"
                type="primary"
                size="small"
                @click="search"
                >搜索</el-button
              >
            </template>
          </el-input>
        </div>
        <div class="save-time-part" :class="{ 'no-text': !saveTime }">
          <i v-show="saveTime" class="icon-save"></i>
          <span>最近保存 </span><span>{{ saveTime }}</span>
        </div>
        <div class="button-box">
          <el-button
            type="primary"
            size="small"
            @click="
              $refs[`editorItemRef${factActiveIndex}-${subheadActiveIndex}`][0].save(
                true,
                facts[factActiveIndex]
              )
            "
            >保存</el-button
          >
          <el-button type="danger" size="small" @click="preview">预览</el-button>
          <el-button type="success" size="small" @click="createdoc"
            >生成质证意见</el-button
          >
        </div>
      </div>

      <!-- <div>				
				<div class="button-box">
					<el-button  :type="isShowFact?'':'primary'" size="small" @click="isShowFact = false" >阅读模式</el-button>
					<el-button  :type="isShowFact?'primary':''" size="small" @click="isShowFact = true" >编辑模式</el-button>
				</div>
			</div> -->
    </div>
    <!-- 界面顶部操作栏结束 -->

    <div class="marking-body">
      <div class="marking-pdf-area">
        <div class="pdf-area-header">
          <div ref="pdfTabs" class="pdf-tabs">
            <div
              v-for="(item, index) in pdfFiles"
              :key="'pdf_tab_' + index"
              class="pdf-tab"
              :class="{ active: index == pdfActiveIndex }"
              :style="pdfOffsetStyle"
              @click="pdfSelect(index)"
            >
              <span :title="item.name">
                {{ item.name }}
              </span>
              <i class="el-icon-error" @click.stop="removePDF(index)"></i>
            </div>
          </div>
          <i
            v-show="pdfSlide"
            class="slide-left el-icon-caret-left"
            :class="{ disabled: pdfOffset == 0 }"
            @click="slideLeft"
          ></i>
          <i
            v-show="pdfSlide"
            class="slide-right el-icon-caret-right"
            :class="{ disabled: banRightSlide }"
            @click="slideRight"
          ></i>
          <div v-if="!isOpenPrintScreen" class="pdf-operation">
            <el-button size="mini" @click="openPDFList">打开案卷</el-button>
            <el-button size="mini" @click="printscreen(0)">识别文字</el-button>
            <el-button size="mini" @click="printscreen(1)">截图</el-button>
          </div>
          <div v-else class="pdf-operation">
            <el-button size="mini" @click="endPrintScreen">取消</el-button>
            <el-button size="mini" @click="printScreenConfirm">确定</el-button>
          </div>
        </div>
        <div class="pdf-area-main">
          <div class="pdf-main">
            <div
              v-for="(item, index) in pdfFiles"
              v-show="index == pdfActiveIndex"
              :key="'pdf_file_' + index"
              class="pdf-main"
            >
              <iframe
                class="pdf-viewbox"
                :src="'./pdf/web/viewer.html?file=' + encodeURIComponent(item.url)"
              ></iframe>
            </div>
            <archives-printscreen
              ref="archivesPrintscreen"
              @cropConfirm="printScreenConfirm"
            >
            </archives-printscreen>
          </div>
        </div>
      </div>
      <div class="marking-operation-area">
        <i
          v-show="factSlide"
          class="slide-left el-icon-caret-left"
          :class="{ disabled: factOffset == 0 }"
          @click="factSlideLeft"
        ></i>
        <i
          v-show="factSlide"
          class="slide-right el-icon-caret-right"
          :class="{ disabled: banFactRightSlide }"
          @click="factSlideRight"
        ></i>
        <div class="operation-header">
          <div ref="factTabs" class="fact-area">
            <div
              v-for="(fact, index) in facts"
              :key="'fact_' + index"
              class="fact-tab"
              :class="{ active: index == factActiveIndex }"
              :style="factOffsetStyle"
              @click="factSelect(index)"
            >
              <i
                v-show="index == factActiveIndex"
                class="el-icon-edit"
                @click.stop="nameEdit(fact.columnTitle, index)"
              ></i>
              <span>
                {{ fact.columnTitle }}
              </span>
              <i
                v-show="index == factActiveIndex"
                class="el-icon-delete"
                @click.stop="factRemove(index)"
              ></i>
            </div>
          </div>
          <el-button class="add-fact-button" size="mini" @click="newFact"
            >添加指控事实</el-button
          >
        </div>
        <div class="operation-subheading">
          <el-popover
            v-for="(tab, index) in subheads"
            :key="'subhead_' + index"
            placement="bottom"
            trigger="hover"
            popper-class="subhead-popover"
            :class="{
              showNum: subheadsSearchNum[tab.type],
              transform: subheadsSearchNum[tab.type].length == 3,
            }"
            :searchResult="subheadsSearchNum[tab.type]"
            :content="tab.name"
          >
            <div
              slot="reference"
              class="subhead-tab"
              :class="{ active: index == subheadActiveIndex }"
              @click="subheadSelect(index)"
            >
              {{ tab.name }}
            </div>
          </el-popover>
          <div class="triangle-icon" :style="triangleStyle"></div>
        </div>

        <div v-for="(items, index) in facts" :key="index">
          <template v-for="(item, i) in subheads">
            <EditorItem
              :key="i"
              v-show="`${index}-${i}` === `${factActiveIndex}-${subheadActiveIndex}`"
              :sign="`${index}-${i}`"
              :ref="`editorItemRef${index}-${i}`"
              :cid="cid"
              :factActiveIndex="factActiveIndex"
              :subheadActiveIndex="subheadActiveIndex"
              :facts="facts"
              :activeTabType="activeTabType"
              @changeSaveTime="setSaveTime"
            />
          </template>
        </div>
      </div>
    </div>
    <archives-dialog ref="archivesDialog" @archives-confirm="archivesConfirm" />

    <pdf-dialog
      ref="pdfDialog"
      @commit="pdfChange"
      @pageChange="pdfPageChange"
      @isFile="isFile"
      :pdfFiles="pdfSelectList"
      :latelyFieles="latelyFieles"
      :pdfPageParam="pdfPageParam"
    />

    <save-dialog :cid="cid" @commit="createdocCallback" ref="saveDialog" />
    <files-dialog @commit="saveMarking" ref="filesDialog" />
    <create-tab @commit="tabsCommit" ref="createTab" />

    <!-- 复制缓存区 -->
    <div class="paste-cache" id="pasterCache"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import message from "@/mixins/message";
import ArchivesDialog from "./components/archives-dialog";
import PdfDialog from "./components/pdf-dialog";
import createTab from "./components/create-tab";
import ArchivesPrintscreen from "./components/archives-printscreen";
import saveDialog from "./components/save-dialog";
import filesDialog from "./components/files-dialog";
import EditorItem from "./components/editor-item.vue";
// import html2canvas from "html2canvas";
// import pdf from "vue-pdf";

import { copyText } from "../../assets/javascript/util";

export default {
  name: "markingIndex",
  mixins: [message],
  components: {
    ArchivesDialog,
    PdfDialog,
    createTab,
    saveDialog,
    filesDialog,
    ArchivesPrintscreen,
    EditorItem,
  },
  data() {
    return {
      cid: "", //案卷id
      isLoading: false,
      keyword: "", //顶部搜索关键词
      pdfFiles: [], //PDF文件列表
      prtSc: false, //是否处于截图或文字识别状态
      pdfSelectList: [], //PDF选择弹窗列表数据
      latelyFieles: [], //PDF选择弹窗-【最近打开】的列表
      pdfPageParam: {
        totalCount: 0,
        currentPage: 1,
        pageCount: 1,
        perPage: 5,
      },
      pdfActiveIndex: 0, //当前激活【PDF文件】标签页序号
      isOpenPrintScreen: false, //是否处于识别文字或截图状态
      isShowFact: false,
      printScreenType: 0, //调用打印弹窗的类型  0识别文字  1截图
      facts: [], //指控事实列表
      factActiveIndex: 0, //当前激活【指控事实】标签页序号
      tabEditIndex: "", //当前名称编辑的tab标签序号
      subheads: [
        {
          type: "material",
          name: "物证",
        },
        {
          type: "documentary",
          name: "书证",
          minWidth: "60px",
        },
        {
          type: "witness",
          name: "证人证言",
        },
        {
          type: "victim",
          name: "被害人陈述",
        },
        {
          type: "suspect",
          name: "犯罪嫌疑人、被告人供述和辩解",
        },
        {
          type: "appraisal",
          name: "鉴定意见（审计报告）",
        },
        {
          type: "inquest",
          name: "勘验、检查、辨认、侦查实验等笔录",
        },
        {
          type: "media",
          name: "视听资料、电子数据",
        },
      ],
      subheadsSearchNum: {
        material: "",
        documentary: "",
        witness: "",
        victim: "",
        suspect: "",
        appraisal: "",
        inquest: "",
        media: "",
      },
      subheadActiveIndex: 0,
      editor: null,
      html: "",
      mode: "default", // or 'simple'                           //【指控事实】- 【类型】激活标签页序号
      timer: null, //自动保存定时器
      pdfSlide: false, //pdf标签页是否需要左右滑动
      pdfOffset: 0, //pdf滑动标签页偏移量
      pdfWholeWidth: 0, //pdf标签页总长度
      factSlide: false, //【指控事实】标签页是否需要左右滑动
      factOffset: 0, //【指控事实】滑动标签页偏移量
      factWholeWidth: 0, //【指控事实】标签页总长度
      hightListActive: 0, //当前高亮序号
      hightListLength: 0, //高亮个数
      inSearch: false, //是否处于搜索状态
      triangleStyleLeft: 0,
      printScreenBase64: "",
      saveTime: "", //最近保存时间
      indent: 0, //文本缩进距离
      textAlign: "justify", //文字对齐方式
      isFileId: null,

      oldSubheadActiveIndex: 0,
      subheadActiveIndexList: [],
    };
  },
  computed: {
    activeTabType() {
      return this.subheads[this.subheadActiveIndex].type;
    },
    activeFactId() {
      if (this.facts.length > 0) {
        return this.facts[this.factActiveIndex].id;
      } else {
        return "";
      }
    },
    pdfOffsetStyle() {
      let style = {};
      const { pdfOffset } = this;
      style.transform = `translateX(-${pdfOffset}px)`;
      return style;
    },
    banRightSlide() {
      const { pdfOffset, pdfWholeWidth } = this;
      if (this.$refs.pdfTabs) {
        const $box = this.$refs.pdfTabs;
        let allWidth = $box.clientWidth;
        return pdfWholeWidth - pdfOffset == allWidth;
      } else {
        return true;
      }
    },
    factOffsetStyle() {
      let style = {};
      const { factOffset } = this;
      if (factOffset > 0) {
        style.transform = `translateX(-${factOffset + 10}px)`;
      } else {
        style.transform = `translateX(0px)`;
      }
      return style;
    },
    banFactRightSlide() {
      const { factOffset, factWholeWidth } = this;
      if (this.$refs.factTabs) {
        const $box = this.$refs.factTabs;
        let allWidth = $box.clientWidth;
        return factWholeWidth - factOffset == allWidth - 3;
      } else {
        return true;
      }
    },

    /**
     * @description 【指控事实】三角标位置，由本身位置偏移和事实拖动偏移共同决定，超出范围隐藏
     */
    triangleStyle() {
      const { triangleStyleLeft, factOffset } = this;
      const $facts = document.getElementsByClassName("fact-area");
      if ($facts && $facts.length > 0) {
        let display = "block";
        let fwidth = $facts[0].clientWidth;
        if (triangleStyleLeft < factOffset) {
          display = "none";
        } else if (triangleStyleLeft > factOffset + fwidth) {
          display = "none";
        }
        return {
          display,
          left: triangleStyleLeft + "px",
          transform: `translateX(-${factOffset + 5}px) scale(0.5)`,
        };
      } else {
        return {
          transform: `scale(0.5)`,
        };
      }
    },
  },
  watch: {
    pdfFiles() {
      this.$nextTick(() => {
        this.pdfSlideCalculate();
      });
    },
    facts() {
      this.$nextTick(() => {
        this.factSlideCalculate();
        setTimeout(() => {
          this.moveTriangle();
        }, 0);
      });
    },
    factActiveIndex() {
      this.moveTriangle();
    },
    inSearch(val) {
      if (val) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      } else {
        if (!this.timer) this.initTimer();
      }
    },
  },
  mounted() {
    const _this = this;
    const { id = null } = this.$route.query;
    this.cid = id;
    if (!id) {
      this.$refs.archivesDialog.show(id);
    } else {
      this.archivesConfirm(id);
    }
  },

  methods: {
    ...mapMutations(["SETMSGDIALOG"]), //引入弹窗提示方法

    /**
     * @description 初始化定时保存，如果保存过，重置定时器
     */
    initTimer() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        setTimeout(() => {
          this.$refs[
            `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
          ][0].save(false, () => {
            this.initTimer();
          });
        }, 0);
      }, 10 * 1000);
    },

    setSaveTime(e) {
      this.saveTime = e;
    },
    /**
     * @description 点击顶部关闭，返回首页
     */
    back() {
      this.$router.push({
        name: "home",
      });
    },

    /**
     * @description 点击pdf标签页，切换显示pdf
     * @param {Number} index 点击的标签页序号
     */
    pdfSelect(index) {
      this.pdfActiveIndex = index;
      this.endPrintScreen();
    },

    /**
     * @description pdf删除
     * @param {Number} index pdf标签页序号
     */
    removePDF(index) {
      this.pdfFiles.splice(index, 1);
      if (this.pdfActiveIndex > 0) {
        this.pdfActiveIndex = this.pdfActiveIndex - 1;
      } else {
        if (this.pdfFiles.length > 0) {
          this.pdfActiveIndex = 0;
        }
      }
    },

    /**
     * @description pdf是否有滑动计划
     */
    pdfSlideCalculate() {
      const $box = this.$refs.pdfTabs;
      let allWidth = $box.clientWidth;
      let $tabs = $box.querySelectorAll(".pdf-tab");
      let wholeWidth = 0;
      for (let i = 0; i < $tabs.length; i++) {
        let $t = $tabs[i];
        wholeWidth += parseFloat($t.clientWidth);
      }
      this.pdfWholeWidth = wholeWidth;
      if (wholeWidth > allWidth) {
        this.pdfSlide = true;
        this.pdfOffset = wholeWidth - allWidth;
      } else {
        this.pdfSlide = false;
        this.pdfOffset = 0;
      }
    },

    /**
     * @description pdf点击左滑
     */
    slideLeft() {
      const { pdfOffset } = this;
      if (pdfOffset > 0) {
        if (pdfOffset < 200) {
          this.pdfOffset = 0;
        } else {
          this.pdfOffset = pdfOffset - 200;
        }
      }
    },

    /**
     * @description pdf点击右滑
     */
    slideRight() {
      const { pdfOffset, pdfWholeWidth } = this;
      const $box = this.$refs.pdfTabs;
      let allWidth = $box.clientWidth;
      let leftOffset = pdfOffset + allWidth;
      if (leftOffset < pdfWholeWidth) {
        if (pdfWholeWidth - leftOffset < 200) {
          this.pdfOffset = pdfWholeWidth - leftOffset;
        } else {
          this.pdfOffset = pdfOffset + 200;
        }
      }
    },

    /**
     * @description 【指控事实】是否需要滑动
     */
    factSlideCalculate() {
      const $box = this.$refs.factTabs;
      if ($box) {
        let allWidth = $box.clientWidth;
        let $tabs = $box.querySelectorAll(".fact-tab");
        let wholeWidth = 0;
        for (let i = 0; i < $tabs.length; i++) {
          let $t = $tabs[i];
          wholeWidth += parseFloat($t.clientWidth);
        }
        this.factWholeWidth = wholeWidth;
        if (wholeWidth > allWidth) {
          this.factSlide = true;
          if (this.factActiveIndex == 0) {
            this.factOffset = 0;
          } else {
            this.factOffset = wholeWidth - allWidth;
          }
        } else {
          this.factSlide = false;
          this.factOffset = 0;
        }
      }
    },

    /**
     * @description pdf点击左滑
     */
    factSlideLeft() {
      const { factOffset } = this;
      if (factOffset > 0) {
        if (factOffset < 200) {
          this.factOffset = 0;
        } else {
          this.factOffset = factOffset - 200;
        }
      }
    },

    /**
     * @description pdf点击右滑
     */
    factSlideRight() {
      const { factOffset, factWholeWidth, facts } = this;
      const $box = this.$refs.factTabs;
      let allWidth = $box.clientWidth;
      let leftOffset = factOffset + allWidth;
      if (leftOffset < factWholeWidth) {
        if (factWholeWidth - leftOffset < 200) {
          this.factOffset = this.factOffset + factWholeWidth - leftOffset + 3;
        } else {
          this.factOffset = factOffset + 200;
        }
      }
    },

    /**
     * @description 结束裁剪，关闭裁剪div，重置裁剪状态
     */
    endPrintScreen() {
      this.isOpenPrintScreen = false;
      this.isShowFact = true;
      this.$refs.archivesPrintscreen.close();
    },

    // 截图确定
    printScreenConfirm() {
      console.log("截图确定===");
      const { printScreenType } = this;
      if (printScreenType == 0) {
        //识别文字
        this.$refs.archivesPrintscreen.save((base64) => {
          let image = base64.replace(/^data:image\/\w+;base64,/, "");
          this.$api.papers
            .getAccurateText({
              image,
            })
            .then((res) => {
              let text = res.words_result.reduce((per, cur) => {
                per = per + cur.words;
                return per;
              }, "");
              if (!text) {
                this.$message.error({
                  message: "识别失败",
                  duration: 1000,
                });
              }
              copyText(text, () => {
                this.$message.success({
                  message: "识别成功，内容已复制到剪切板",
                  duration: 2000,
                });
              });
            })
            .catch((err) => {});
        });
      } else {
        this.$refs.archivesPrintscreen.save(async (data) => {
          const res = await this.$api.system.getGlobalList({
            type: "ossScreenshotSign",
          });

          const {
            ossScreenshotSign: {
              accessid,
              host,
              policy,
              signature,
              path,
              name,
              // expire
            },
          } = res;

          let formData = new FormData();
          formData.append("key", `${path}/${name}.jpg`);
          formData.append("policy", policy);
          formData.append("OSSAccessKeyId", accessid);
          formData.append("signature", signature);
          formData.append("file", data);
          formData.append("success_action_status", 200);

          this.$api.system.upload(host, formData).then(() => {
            let srcPath = `${host}/${path}/${name}.jpg`; //?OSSAccessKeyId=${accessid}&Expires=${new Date(expire).getTime()/1000}&Signature=${signature}`
            copyText("printScreen-" + srcPath, () => {
              this.$message.success({
                message: "截图成功，内容已复制到剪切板",
                duration: 2000,
              });
            });
          });
        }, 1);
        // this.$refs.archivesPrintscreen.save((base64) => {
        //   let file = dataURLtoFile(base64);
        //   this.upload(file);
        // });
      }
      this.isOpenPrintScreen = false;
      this.isShowFact = true;
    },

    /**
     * @description 文字识别或截图按钮点击回调，切换为界面渲染pdf方式
     * @param type 0文字识别 1截图
     */
    printscreen(type) {
      this.printScreenType = type;
      let frameDocument = window.frames[this.pdfActiveIndex].document;
      let $container = frameDocument.querySelector("#viewerContainer"); //pdf渲染容器
      let $box = $container.querySelector("#viewer");
      // let $firstPage = $container.querySelector(".page");                 //pdf第一页，用来获取每一页的高度
      // let unitHeight = parseInt($firstPage.style.height);                 //每一页的高度
      let sTop = $container.scrollTop; //视窗顶部位置
      let imagesSrcArr = [];
      let bottomEnd = sTop + $container.clientHeight; //试窗底部位置
      let ifLeftOpen = false;
      try {
        if (frameDocument.querySelector("#outerContainer").className.length > 0) {
          ifLeftOpen = true;
        }
      } catch (e) {
        console.log(e);
      }
      $container.querySelectorAll(".page").forEach(($dom, index) => {
        //判断每个画面是否在视窗内
        let unitHeight = $dom.clientHeight;
        let ot = $dom.offsetTop;
        let bottom = ot + unitHeight;
        let rect = $dom.getBoundingClientRect();
        let left = 10;
        if (rect) {
          if (rect.x < 0) {
            left = parseInt(rect.x + 20);
            if (ifLeftOpen) {
              left = left - 200;
            }
          }
        }
        let $canvas = $dom.querySelector("canvas");
        if ($canvas) {
          let src = $canvas.toDataURL("image/jpeg", 1);
          if (bottom > bottomEnd && ot - 1 <= sTop) {
            //视窗内只有一张图，图片顶部高于视窗，底部低于视窗，这个1是因为滚动条置顶时，offsetTop可能为1
            imagesSrcArr.push({
              src,
              left,
              height: unitHeight,
              width: $dom.clientWidth,
              startPos: ot - sTop + 11,
            });
          } else if (bottom > sTop && bottom < bottomEnd) {
            //图片底部在视窗范围内
            let showHeight = bottom - sTop;
            if (ot < sTop && sTop - ot < 5) {
              //顶部黑边卡在视窗口
              showHeight = unitHeight;
            }
            imagesSrcArr.push({
              src,
              left,
              height: unitHeight,
              width: $dom.clientWidth,
              showHeight: showHeight,
            });
          } else if (bottom > bottomEnd && ot < bottomEnd) {
            //图片顶部在视窗范围内
            imagesSrcArr.push({
              src,
              left,
              height: unitHeight,
              width: $dom.clientWidth,
              showHeight: unitHeight,
            });
          } else if (ot < sTop && sTop - ot <= 9) {
            if (Math.abs(bottom - bottomEnd) <= 9) {
              imagesSrcArr.push({
                src,
                left,
                height: unitHeight,
                width: $dom.clientWidth,
                showHeight: unitHeight,
              });
            }
          }
        }
      });
      if (imagesSrcArr.length > 1) {
        //如果存在多张图，为每一张图设定绘画起始位置，只有一张图的情况已在上方处理
        let startPos = 0;
        for (let i = 0; i < imagesSrcArr.length; i++) {
          let image = imagesSrcArr[i];
          if (image.showHeight !== image.height && i == 0) {
            //第一张图，如果不是全部显示在视窗内，则起始位置为负数
            startPos = image.showHeight - image.height;
          } else {
            //第一章图之后的图，依次添加上方图的高度和边框
            if (i == 0) {
              startPos = 5;
            } else {
              startPos += imagesSrcArr[i - 1].height + i * 11; //11是页之间的间距，估计是9+2个1px的边框
            }
          }
          image.startPos = startPos;
        }
      } else if (imagesSrcArr.length == 1) {
        //底部只有一张pdf情况处理
        if (
          $container.scrollTop + $container.clientHeight + 2 >=
          $container.scrollHeight
        ) {
          imagesSrcArr[0].startPos = $container.clientHeight - imagesSrcArr[0].height;
        } else if (imagesSrcArr[0].showHeight == imagesSrcArr[0].height) {
          //卡在缝隙的情况处理
          imagesSrcArr[0].startPos = 5;
        } else if (
          imagesSrcArr[0].height - imagesSrcArr[0].showHeight < 5 &&
          !imagesSrcArr[0].startPos
        ) {
          //卡在缝隙的情况处理（增补处理）
          imagesSrcArr[0].startPos = 5;
        }
      }

      if (!imagesSrcArr || imagesSrcArr.length == 0) {
        this.$message.error("未获取到截图页面，请稍微滚动PDF界面重试");
        return false;
      } else if (imagesSrcArr.length == 1) {
        let img = imagesSrcArr[0];
        if (img.startPos >= img.showHeight || !img.startPos) {
          img.startPos = 5;
        }
      }

      if (imagesSrcArr.some((i) => !i.startPos)) {
        this.$message.error("未获取到截图页面，请稍微滚动PDF界面重试");
        return false;
      }

      // let canvasElement = this.mergeImage(imagesSrcArr,$container,unitHeight,parseInt($firstPage.style.width),sTop % (unitHeight+11))//11是页之间的间距，估计是9+2个1px的边框
      let canvasElement = this.mergeImage(
        imagesSrcArr,
        $container,
        parseInt($box.clientWidth)
      ); //11是页之间的间距，估计是9+2个1px的边框
      this.isOpenPrintScreen = true;
      setTimeout(() => {
        console.log(canvasElement, $box.clientWidth, $container);
        this.$refs.archivesPrintscreen.tailoring(canvasElement);
      }, 200); //延时不为0就行............你敢信！！！！！四点了，老子真是个天才
      // let $pageNumer = document.querySelector("#numPages");
      // let pageNum = $pageNumer.innerText.split("/")[0];
      // if( pageNum ){
      //     pageNum = pageNum.trim().substr(1)
      // }else{
      //     let $num = document.querySelector("#pageNumber");
      //     pageNum = $num.value;
      // }
      // let $num = document.querySelectorAll("div[data-page-number='"+pageNum+"']");
      // let $canvas;
      // for( let i = 0 ; i < $num.length ; i ++ ){
      //     if( $num[i].className == "page" ){
      //         $canvas = $num[i];
      //         break;
      //     }
      // }

      // this.isOpenPrintScreen = true;
      // this.$refs.archivesPrintscreen.tailoring($canvas.querySelector("canvas"))
    },

    /**
     * @description 原画复制合并
     */
    mergeImage(arr, $box, unitWidth) {
      let canvas = document.createElement("canvas");
      console.log($box);
      let scale = 2;
      canvas.width = unitWidth * scale;
      canvas.height = $box.clientHeight * scale;
      canvas.style.height = $box.clientHeight + "px";
      canvas.style.width = unitWidth + "px";
      let context = canvas.getContext("2d");
      context.scale(scale, scale);
      context.fillStyle = "#404040";
      context.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < arr.length; i++) {
        let img = new Image();
        img.src = arr[i].src;
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          let left = arr[i].left;
          if (!left) {
            left = (canvas.width - arr[i].width) / 2;
          }
          // context.drawImage(img , 0 , i * (unitHeight+10) - startPos , canvas.width , unitHeight);
          context.drawImage(img, left, arr[i].startPos, arr[i].width, arr[i].height);
          //context.drawImage(img , (canvas.width - arr[i].width)/4 , arr[i].startPos * 2, arr[i].width * 2 , arr[i].height * 2);
        };
      }
      return canvas;
    },

    /**
     * @description 点击【指控事实】标签页，切换显示内容和副标题
     * @param {Number} index 点击的标签页序号
     */
    factSelect(index) {
      this.cancelSearch(false);
      // this.$refs[
      //   `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
      // ][0].save(false); //检索方法会默认保存一次
      this.factActiveIndex = index;
      this.subheadSelect(this.subheadActiveIndex);
       //this.loadPapers();
    },

    /**
     * @description 计算三角的对应偏移量
     */
    moveTriangle() {
      this.$nextTick(() => {
        let $sel = document.getElementsByClassName("fact-tab active")[0];
        let left = $sel.offsetLeft;
        this.triangleStyleLeft = left;
        // console.log($sel,$sel.clientWidth,$sel.offsetTop,$sel.offsetLeft)
      });
    },

    /**
     * @description 点击【指控事实】副标题标签页，切换具体内容
     * @param {Number} index 点击的标签页序号
     */
    subheadSelect(index) {
      let that = this
      //if (!this.inSearch)//2024年7月4日15:02:34 去掉搜索不能保存
      this.$refs[
        `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
      ][0].save(false); //检索方法会默认保存一次

      this.oldSubheadActiveIndex = this.subheadActiveIndex;
      this.subheadActiveIndexList.push( this.subheadActiveIndex);
      this.subheadActiveIndex = index;
      setTimeout(()=>{
        that.loadPapers();
      },500)
    
    },

    /**
     * @description 界面进来的案卷弹窗-选中年份案卷-确认回调
     * @param {String} id 案卷序号
     */
    archivesConfirm(id) {
      this.isLoading = true;
      this.cid = id;
      Promise.all([
        this.$api.papers.getFiles({
          cid: id,
        }),
        // this.$api.papers.getReviewList({
        //   cid: id,
        // }),
        this.$api.papers.getColumnList({
          cid: id,
        }),
      ])
        .then((res) => {
          // this.isLoading = false;
          // console.log("案卷弹窗回调==", res);
          this.pdfSelectList = res[0].files;
          this.latelyFieles = res[0].lately;
          this.$set(
            this,
            "pdfPageParam",
            Object.assign({}, res[0].page, {
              layout: "prev, pager, next, jumper",
            })
          );

          this.facts = res[1];
          this.loadPapers(res);
          this.isLoading = false;
          this.openPDFList();
          this.initTimer();
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    /**
     * @description pdf分页切换
     */
    pdfPageChange(currentPage) {
      this.$api.papers
        .getFiles({
          cid: this.cid,
          page: currentPage,
          folder_id: this.isFileId,
        })
        .then((res) => {
          this.pdfSelectList = res.files;
          this.$set(
            this,
            "pdfPageParam",
            Object.assign({}, res.page, {
              layout: "prev, pager, next, jumper",
            })
          );
        });
    },

    /**
     * @description 添加指控事实新增
     */
    newFact() {
      this.$refs.createTab.show({ type: "add" });
    },

    /**
     * @description 点击名称编辑按钮触发
     * @param {String} oldName 案卷原有名称
     * @param {Number} index 触发案卷序号
     */
    nameEdit(oldName, index) {
      this.$refs.createTab.show({ type: "edit", name: oldName });
      this.tabEditIndex = index;
    },

    /**
     * @description 名称编辑确认回调
     * @param {String} oldName 案卷原有名称
     */
    tabsCommit(row) {
      this.cancelSearch(false);
      const { params, options } = row;
      const { cid } = this;
      const nowTab = this.facts[this.tabEditIndex];
      if (options.type === "edit") {
        this.$api.papers
          .modifyColummn({ title: params.name, cid, colId: nowTab.id })
          .then((res) => {
            this.facts[this.tabEditIndex].columnTitle = params.name;
          });
      } else {
        this.$api.papers.addColumn({ title: params.name, cid }).then((res) => {
          res.id = res.colId;
          //res.columnItems.forEach(i=>i.id = i.colId)
          //let arr = res.columnItems.concat(this.facts);
          this.$set(this, "facts", res.columnItems);
          // this.facts = res.columnItems.concat(this.facts);
          this.tabEditIndex = this.facts.length - 1;
          this.factActiveIndex = this.facts.length - 1;
          //this.tabEditIndex = 0;
          this.success({
            message: `新增指控事实成功`,
            duration: 1000,
          });
          //this.factActiveIndex = 0;
          this.$nextTick(() => {
            this.loadPapers();
          });
        });
      }
    },

    /**
     * @description 删除指控事实
     * @param {Number} index 删除的案卷序号
     */
    factRemove(index) {
      const tab = this.facts[index];
      const { id: colId, columnTitle } = tab;
      const { cid } = this;
      this.SETMSGDIALOG({
        title: "系统提示",
        message: `是否删除【${columnTitle}】？`,
        width: "439px",
        type: "error",
        commit: () => {
          this.$api.papers.deleteColummn({ colId, cid }).then((res) => {
            let index = this.facts.findIndex((f) => f.id == colId);
            this.facts.splice(index, 1);
            if (index > 0) {
              this.factActiveIndex = this.factActiveIndex - 1;
            }
            this.SETMSGDIALOG(null);
            this.success({
              message: `删除【${columnTitle}】成功`,
              duration: 1000,
            });
            this.loadPapers();
          });
        },
      });
    },

    getPapers(successCallback) {
      const { activeFactId, activeTabType } = this;
      this.$api.papers
        .getReviewList({
          cid: this.cid,
          ev: activeTabType,
          colId: activeFactId,
          keyword: this.searchKeyword || null,
          page: 1,
          perPage: 1000,
        })
        .then((res) => {
          successCallback && successCallback.call(this, res);
        })
        .catch((e) => {
          console.log(e);
        });
      return;
    },

    /**
     * @description 加载编辑器内容
     */
    loadPapers() {
      if (!this.inSearch) {
    
        this.isRefresh = true;
        this.isLoading = true;
        this.getPapers((res) => {
        
          if (res.isLocked) {
           
            this.error({
              message: "当前有人正在操作",
            });
            this.openArchives();
          } else {
         
            let { content } = res;
          
            if (!content || content.length == 0) {
             
              this.$refs[
                `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
              ][0].handleSetJSON();
            } else {
               this.$refs[
                  `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                ][0].editor.txt.html(content);
              // console.log(this.factActiveIndex);
              // console.log(this.subheadActiveIndex);
              // console.log( this.subheadActiveIndexList)
              //  let data = this.subheadActiveIndexList.find(item => item == this.subheadActiveIndex);
              // console.log('data');
              // console.log(data);
              // if(!data){
              //   console.log("7777777777777");
              //   if(data == 0 && this.subheadActiveIndexList.length >0){
                  
              //   }else{
                  
              //   }
                
              // }
            
             
            }
          }
          this.isLoading = false;
        });
        // .finally(() => (this.isRefresh = false));
      } else {
        this.search();
      }
    },

    /**
     * @description 搜索结果定位高亮
     */
    focusHightLight() {
      this.$refs[
        `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
      ][0].focusHightLight(this.hightListActive);
    },

    /**
     * @description 定位上一个搜索结果
     */
    lastHightLight() {
      const { hightListActive, hightListLength } = this;
      if (hightListActive > 0) {
        this.hightListActive--;
        this.focusHightLight();
      }
    },

    /**
     * @description 定位下一个搜索结果
     */
    nextHightLight() {
      const { hightListActive, hightListLength } = this;

      if (hightListActive < hightListLength - 1) {
        this.hightListActive++;
        this.focusHightLight();
      }
    },

    /**
     * @description 打开pdf列表弹窗499
     */
    openPDFList() {
      this.$refs.pdfDialog.show();
    },

    /**
     * @description pdf选中回调
     * @param {String} item pdf案卷对象
     */
    async pdfChange(item) {
      this.pdfFiles.push(item);
      this.$refs.pdfDialog.visible = false;
      this.pdfActiveIndex = this.pdfFiles.length - 1;
      await this.$api.papers.pagerLately({
        cid: this.cid,
        fileId: item.id,
      });

      this.$api.papers
        .getFiles({
          cid: this.cid,
        })
        .then((res) => {
          this.$set(this, "latelyFieles", res.lately);
        });
    },

    isFile(e) {
      this.isFileId = e.id;
      this.$api.papers
        .getFiles({
          cid: this.cid,
          folder_id: e.id,
          page: 1,
        })
        .then((res) => {
          this.pdfSelectList = res.files;

          this.$set(
            this,
            "pdfPageParam",
            Object.assign({}, res.page, {
              layout: "prev, pager, next, jumper",
            })
          );
        });
    },

    async search() {
      this.isLoading = true;

      if (!this.inSearch) {
        await this.$refs[
          `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
        ][0].save(false);
      }

      const { keyword } = this;
      if (keyword.length > 0) {
      setTimeout(()=>{
        this.$api.papers
          .keyWordSearch({
            cid: this.cid,
            colId: this.facts[this.factActiveIndex].id,
            keyword,
          })
          .then((res) => {
            
            Object.entries(res).forEach(([type, obj]) => {
              this.isLoading = false;
              let { count } = obj;
              if (count > 99) count = "...";
              this.subheadsSearchNum[type] = count;
            });
            if (res && Object.keys(res).length > 0) {
              let data = res[this.activeTabType];

              if (data) {
                this.$refs[
                  `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                ][0].editor.txt.html(data.formattedContent || data.content);
                this.hightListActive = 0;
                this.hightListLength = data.count;
                this.focusHightLight();
                this.inSearch = true;
              } else {
                //部分标签页有搜索内容，切换到无搜索结果的情况
                this.getPapers((res) => {
                  let { content } = res;
                  if (!content || content.length == 0) {
                    this.$refs[
                      `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                    ][0].handleSetJSON();
                  } else {
                    this.$refs[
                      `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                    ][0].editor.txt.html(content);
                  }
                  this.inSearch = true;
                  this.isLoading = false;
                });
              }
            } else {
              //所有标签页都没有搜索内容，切换标签页的情况
              this.$message.warning("未搜到到对应内容");
              this.getPapers((res) => {
                let { content } = res;
                if (!content || content.length == 0) {
                  this.$refs[
                    `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                  ][0].handleSetJSON();
                } else {
                  this.$refs[
                    `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
                  ][0].editor.txt.html(content);
                }
                this.inSearch = true;
                this.isLoading = false;
              });
            }
          });
      },500)
      
      } else {
        this.$message.error("搜索关键词不能为空");
        this.isLoading = false;
      }
    },

    /**
     * @description 取消搜索状态，重置所有角标数字和关键词
     * @param {Boolean} resetEditor 是否触发重置编辑器内容（如果此处由切换标签页触发，会由另一个函数触发切换内容，不需要额外触发）
     */
    cancelSearch(resetEditor = true) {
      this.inSearch = false;
      if (resetEditor) {
        this.factSelect(this.factActiveIndex);
      }
      this.keyword = "";
      this.hightListActive = 0;
      Object.keys(this.subheadsSearchNum).forEach((key) => {
        this.subheadsSearchNum[key] = "";
      });
    },

    /**
     * @description 预览点击回调
     */
    async preview() {
      await this.$refs[
        `editorItemRef${this.factActiveIndex}-${this.subheadActiveIndex}`
      ][0].save(false);
      this.$api.papers.preview({ cid: this.cid }).then((res) => {
        const { file } = res;
        window.open(file);
      });
    },

    /**
     * @description 【生成质证意见】点击回调
     */
    // createdoc(){
    //     this.$api.papers.createdoc({cid:this.cid}).then(res=>{
    //         this.success({
    //             message: "保存成功",
    //         });
    //     })
    // },

    /**
     * @description 【生成质证意见】点击回调
     */
    createdoc() {
      this.$refs.saveDialog.show();
    },

    // 保存回调
    createdocCallback(type) {
      if (type) {
        this.saveMarking();
      } else {
        this.$refs.filesDialog.show(this.cid);
        this.$refs.saveDialog.visible = false;
      }
    },

    // 保存阅卷资料
    saveMarking(fileId = undefined) {
      const { cid } = this;

      this.$api.papers
        .createdoc({
          fileId,
          cid,
        })
        .then((res) => {
          this.success({
            tip: `保存在“${res.name}”文件夹内`,
            message: "保存成功",
            duration: 1000,
          });
          this.$refs.saveDialog.visible = false;
          this.$refs.filesDialog.visible = false;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.marking-container {
  .el-loading-mask {
    background: transparent !important;
   
  }

  .marking-header {
    width: 100vw;
    height: 44px;
    background: #fafbfd;
    box-shadow: 0px 1px 0px 0px #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    line-height: 44px;
    padding-right: 15px;
    position: relative;

    > div {
      // width: 50%;
      // flex:1;
      &:first-child {
        padding-left: 23px;

        i {
          color: #888888;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;
        }
      }

      &.marking-header-main {
        display: flex;
        line-height: 0;
        justify-content: flex-end;
        align-items: center;

        // flex:3;
        // padding-left: 48px;
        .el-input__suffix-inner {
          .search-button {
            border-radius: 0px 4px 4px 0px;
            transform: translateX(5px);
          }

          .in-search-box {
            transform: translateX(71px);
            display: inline-flex;
            align-items: center;
            height: 30px;
            color: #056bfd;

            .search-result-box {
              i {
                font-size: 24px;
                cursor: pointer;

                &:first-child {
                  position: relative;
                  top: 1px;
                  // right: -13px;
                }

                &:last-child {
                  position: relative;
                  top: -1px;
                  right: 4px;
                }

                &.disabled {
                  color: #ccc;
                }
              }
            }

            > span {
              margin-left: 15px;
              cursor: pointer;
            }
          }
        }

        .search-input-box {
          min-width: 230px;
          // flex: 1;
          // width: calc(100% - 450px);
          // max-width: 70%;
          margin-right: 50px;

          .el-input-group__append {
            button {
              height: 32px;
              border: 1px solid #056bfd;
              background: #056bfd;
              color: #fff;
              border-radius: 0px 4px 4px 0px;
            }
          }
        }

        .save-time-part {
          // width: 165px;
          display: flex;
          align-items: center;
          color: #999999;
          min-width: 120px;
          margin: 0 16px;
          text-align: right;
          justify-content: flex-end;

          > i {
            width: 14px;
            height: 14px;
            background: url("../../assets/image/marking/icon_save_success@2x.png")
              no-repeat;
            background-size: 100%;
            margin-right: 4px;
          }

          &.no-text {
            color: #ffffff;
          }
        }

        .button-box {
          // width: 250px;
          text-align: right;

          .el-button {
            height: 30px;
            line-height: 0;
          }
        }
      }
    }
  }

  .marking-body {
    display: flex;
    height: calc(100vh - 75px);
    background: #fff;

    > div {
      width: 50%;
      margin: 0 auto;
      max-width: 50vw;

      // &.w75{
      // 	width: 70%;
      // }
      // &.w25{
      // 	width: 30%;
      // }
      &.marking-pdf-area {
        padding-left: 15px;
        overflow: hidden;
        border-right: #dddddd;

        .pdf-area-header {
          position: relative;
          border-right: 1px solid #dcdfe6;

          > i {
            position: absolute;
            cursor: pointer;
            color: #056bfd;
            font-size: 16px;
            top: 15px;

            &.slide-left {
              left: -14px;
            }

            &.slide-right {
              right: 280px;
            }

            &.disabled {
              opacity: 0.2;
            }
          }

          > div {
            display: inline-flex;
            height: 36px;
            align-items: center;

            &.pdf-tabs {
              width: calc(100% - 300px);
              position: relative;
              overflow: hidden;

              .pdf-tab {
                min-width: 100px;
                max-width: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 34px;
                line-height: 36px;
                padding: 0 10px 0 20px;
                background: #f0f2f5;
                border: 1px solid #dcdfe6;
                position: relative;
                top: 3px;
                cursor: pointer;
                transition: transform 0.2s;

                &:first-child {
                  border-radius: 4px 0px 0px 0;
                }

                &:last-child {
                  border-radius: 0px 4px 0 0px;
                }

                &.active {
                  background: #fff;
                  border-bottom: none;
                }

                span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                i {
                  margin-left: 16px;
                  color: #c0c4cc;
                }
              }
            }

            &.pdf-operation {
              width: 260px;
              justify-content: flex-end;
              padding-right: 15px;
              margin-left: 40px;

              .el-button {
                padding: 8px 10px;
                line-height: 14px;
                border-radius: 4px;

                &:hover,
                &:focus {
                  color: #fff;
                  background: #056bfd;
                  border-color: #056bfd;
                }
              }
            }
          }
        }

        .pdf-area-main {
          height: calc(100vh - 123px);
          border-right: 1px solid #dcdfe6;

          .pdf-main {
            height: 100%;
            position: relative;

            .pdf-viewbox {
              width: calc(100% - 2px);
              height: 100%;

              &::-webkit-scrollbar {
                width: 0;
              }
            }
          }
        }
      }

      &.marking-operation-area {
        position: relative;
        padding: 10px 15px 10px 40px;

        > i {
          position: absolute;
          cursor: pointer;
          color: #056bfd;
          font-size: 16px;
          top: 25px;

          &.slide-left {
            left: 24px;
          }

          &.slide-right {
            right: 135px;
          }

          &.disabled {
            opacity: 0.2;
          }
        }

        .operation-header {
          display: flex;
          justify-content: space-between;

          .fact-area {
            width: calc(100% - 150px);
            height: 40px;
            display: inline-flex;
            overflow: hidden;
            padding-right: 10px;

            .fact-tab {
              display: inline-flex;
              align-items: center;
              height: 36px;
              line-height: 36px;
              padding: 0 10px;
              background: #fff;
              border: 1px solid #dcdfe6;
              border-left: none;
              position: relative;
              top: 3px;
              cursor: pointer;
              max-width: 250px;
              min-width: 120px;
              text-align: center;
              transition: transform 0.2s;

              &:first-child {
                border-radius: 8px 0px 0px 8px;
                // border-left: 1px solid #DCDFE6;
              }

              &:last-child {
                border-radius: 0px 8px 8px 0px;
              }

              &.active {
                background: #056bfd;
                color: #fff;
                min-width: 150px;
                border-color: #056bfd;

                i {
                  color: #fff;
                }

                &::before {
                  content: " ";
                  position: absolute;
                  left: calc(50% - 5px);
                  bottom: -14px;
                  background: transparent;
                  border-bottom: 10px solid#056BFD;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                }

                &::after {
                  content: " ";
                  position: absolute;
                  left: calc(50% - 4px);
                  bottom: -14px;
                  background: transparent;
                  border-bottom: 9px solid #f5f9ff;
                  border-left: 9px solid transparent;
                  border-right: 9px solid transparent;
                }

                span {
                  margin: 0 15px 0 5px;
                }
              }

              i {
                color: #c0c4cc;
              }

              span {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .add-fact-button {
            height: 36px;
            border-radius: 8px;
            border: 1px solid #dcdfe6;
            font-weight: 400;
            color: #006aff;
            font-size: 14px;
            padding: 0 17px;
          }
        }

        .operation-subheading {
          height: 46px;
          background: #f5f9ff;
          border: 1px solid #82b5fe;
          border-radius: 8px;
          width: 100%;
          margin-top: 15px;
          padding: 8px 8px 8px 3px;
          display: flex;
          width: calc(100% - 10px);
          margin-bottom: 10px;
          position: relative;

          > span {
            min-width: calc(25% - 85px);
            min-width: 68px;

            &:nth-child(1),
            &:nth-child(2) {
              min-width: 68px;
            }

            &:nth-child(3) {
              min-width: 105px;
            }

            &:nth-child(4) {
              min-width: 110px;
            }
          }

          .subhead-tab {
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 8px;
            height: 30px;
            background: #ffffff;
            border-radius: 4px;
            text-align: center;
            line-height: 30px;
            padding: 0 15px;
            flex: 1;
            cursor: pointer;
            border: 1px solid #e3e9f2;

            &.active {
              background: #056bfd;
              color: #fff;
              border-color: #056bfd;
            }

            // &:nth-child(3).active {
            //     min-width: 90px;
            // }
            // &:nth-child(4).active {
            //     min-width: 100px;
            // }
            // &:nth-child(5).active {
            //     min-width: 230px;
            // }
            // &:nth-child(6).active {
            //     min-width: 160px;
            // }
            // &:nth-child(7).active {
            //     min-width: 250px;
            // }
            // &:nth-child(8).active {
            //     min-width: 160px;
            // }
          }

          .triangle-icon {
            position: absolute;
            left: 0;
            top: -16px;
            width: 40px;
            height: 22px;
            background: url("../../assets/image/marking/icon_sanjiaoxing@2x.png")
              no-repeat;
            transform: scale(0.5);
          }
        }
      }
    }
  }
}

.subhead-popover {
  padding: 10px;
  text-align: center;
  min-width: 80px;
}

span[searchResult].showNum {
  position: relative;

  &::after {
    content: attr(searchResult);
    position: absolute;
    display: block;
    font-size: 10px;
    top: -6px;
    right: -6px;
    color: #fff;
    background: #d81414;
    height: 17px;
    padding: 2px 3px;
    min-width: 17px;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    transform: scale(0.8);
  }

  &.transform {
    &::after {
      line-height: 8px;
    }
  }
}

.paste-cache {
  position: fixed;
  top: -1000px;
  left: -1000px;
  height: 50px;
  width: 50px;
}
</style>

<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="626px"
    title="选取覆盖文件">
    <div class="file-table">
      <div class="table-th">
        <div class="td f-1">
          <span>名称</span>
        </div>
        <div class="td" style="width: 200px">
          <span>修改时间</span>
        </div>
      </div>
      <template v-if="files && files.length">
        <div
          @click="commit(v.fileId)"
          v-for="(v, i) in files" :key="i" class="table-tr pointer not-select">
          <div class="td f-1">
            <span>{{ v.fileName }}</span>
          </div>
          <div class="td" style="width: 200px">
            <span>{{ v.updateDate }}</span>
          </div>
        </div>
      </template>
      <div v-else class="table-tr">
        <div class="td td-nothing f-1">
          <span>暂无文件</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import message from '@/mixins/message'
export default {
  mixins: [message],
  data() {
    return {
      visible: false,
      files: []
    };
  },
  methods: {
    // 确认
    commit(id) {
      this.$emit('commit', id)
    },
    // 显示
    show(cid) {
      this.visible = true;

      // this.$api.papers.getSaveFiles({
      this.$api.papers.getOldFileList({
        cid
      }).then(res => {
        this.files = res || []
      })
    },
    // 关闭重置
    closed() {
      this.files = []
    }
  }
};
</script>

<style lang="scss" scoped>
.file-table {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $border;
  overflow: hidden;
  .table-tr,
  .table-th {
    display: flex;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid $line;
    }
    > .td {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      font-size: 14px;
      color: $primary_text;
      &:not(:last-child) {
        border-right: 1px solid $line;
      }
      &-nothing {
        justify-content: center;
        color: $tertiary_text;
      }
    }
  }
  .table-th {
    background: $primary_bg;
  }
}
</style>

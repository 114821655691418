<template>
  <div>
    <div v-show="editorData.length > 0" class="editor-content-nav">
      <el-popover v-model="navVisible" placement="bottom" width="250" trigger="click" :visible-arrow="false" :offset="110"
        popper-class="editor-nav-popper">
        <div class="editor-nav-box">
          <a v-for="(item, index) in editorData" :key="item.eindex + '_' + index" :href="item.href"
            class="editor-nav-unit" @click="navVisible = false">
            {{ item.title }}
          </a>
        </div>
        <div slot="reference" class="editor-content-nav-button"></div>
      </el-popover>
    </div>
    <div v-show="editorData.length > 0" title="模板重新排序" class="editor-reset-index" @click="resetIndex">
      <i class="el-icon-refresh"></i>
    </div>
    <div class="marking-editor editor-area" :id="`editor${sign}`" style="{'fontSize':'15px'}" 
      :style="{ 'line-height': lineHeight }"></div>
  </div>
</template>
<script>
import E from "wangeditor";
export default {
  props: {
    sign: {
      type: String,
      default: "",
    },
    cid: {
      type: Number,
      default: "",
    },
    factActiveIndex: {
      type: Number,
      default: 0,
    },
    subheadActiveIndex: {
      type: Number,
      default: 0,
    },
    facts: {
      type: Array,
      default: () => [],
    },
    activeTabType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorData: [], //编辑器数据
      editorUnitNumber: 0, //编辑器中模板个数
      editor: null,

      navVisible: false,
      lineHeight: 1.8,
    };
  },
  computed: {},

 

  mounted() {
    this.creatEditor();
  },
  methods: {
   

    creatEditor() {
      let _this = this;
      const editor = new E(`#editor${this.sign}`);

      // 配置 onchange 回调函数
      editor.config.onchange = this.contentChange;
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms

      editor.config.placeholder = "在框内输入Ctrl+P快捷新增模板";

      editor.config.pasteTextHandle = this.pasteText;

      // 菜单 key ，各个菜单不能重复
      // 注册菜单
      // E.registerMenu('customAlign' , _this.getCustomAlign())

      // E.registerMenu('customLineHeight' , _this.getCustomLineHeight())

      // E.registerMenu('customRetract' , _this.getCustomRetract())

      // 配置菜单栏，删减菜单，调整顺序
      editor.config.menus = [
        // 'head',
        "bold",
        //'fontSize',
        //'fontName',
        //'italic',
        "underline",
        // 'strikeThrough',
        //'justify',
        // 'indent',
        // 'lineHeight',
        // 'foreColor',
        // 'backColor',
        "undo",
        "redo",
      ];
      editor.create();
      this.editor = editor;
      document.onkeydown = function (event) {
        if (event.ctrlKey && window.event.keyCode == 83) {
          //禁用ctrl + s 功能
          return false;
        }
        if (event.ctrlKey && window.event.keyCode == 80) {
          //禁用ctrl + p 功能
          return false;
        }
        if (event.keyCode == 8 || window.event.keyCode == 8) {
          _this.moreDelete(event); //第一个模板不能删除特殊处理
        }
        //if ( window.event.keyCode==8 ){ //禁用ctrl + p 功能
        //    return false;
        //}
      };
      editor.txt.eventHooks.keyupEvents.push(this.keyUpEvent);

      document.getElementById(`editor${this.sign}`).addEventListener("click", (e) => {
        if (e.path && e.path.some((ele) => ele.className == "item-unit")) {
          let $unit = e.path.find((ele) => ele.className == "item-unit");
          this.addSpecialTemplate($unit);
        } else if (e.target.className == "item-unit") {
          //火狐兼容处理
          this.addSpecialTemplate(e.target);
        }
      });
    },

    moreDelete(event) { },

    pasteText(pasteStr) {
      if (pasteStr.substr(0, 12) == "printScreen-") {
        let src = pasteStr.split("printScreen-")[1];
        let html = `<img id="newScreenPrint" style="max-width: 100%;" src="${src}"/>`;
        this.editor.cmd.do("insertHTML", html);
        let $img = document.getElementById("newScreenPrint");
        $img.onload = () => {
          let iwidth = $img.clientWidth;
          let iheight = $img.clientHeight;
          if (iwidth > 600) {
            let scale = iwidth / 600;
            iwidth = 600;
            iheight = parseInt(iheight / scale);
          }
          $img.setAttribute("iwidth", iwidth);
          $img.setAttribute("iheight", iheight);
          $img.removeAttribute("id");
        };
        return "";
      } else if (/data:image\/\w+;base64,/g.test(pasteStr)) {
        let src = pasteStr;
        if (pasteStr.startsWith("<p>")) {
          src = pasteStr.slice(3, -4);
        }
        return `<img src='${src}'/>`;
        // return `<p><img src=${src} style="max-width:100%;" contenteditable="false"/></p>`;
      } else {
        if (pasteStr.startsWith("<p>")) {
          pasteStr = pasteStr.slice(3, -4);
        }
        let $paste = document.getElementById("pasterCache");
        $paste.innerHTML = pasteStr;
        let text = $paste.innerText.replace(/\n/gi, "");
        return text;
      }
    },

    /**
     * @description 对编辑器模板内容进行重新排序
     */
    resetIndex() {
      let json = this.editor.txt.getJSON();

      json.forEach((j, nIndex) => {
        let attrs = j.attrs;
        attrs.forEach((a) => {
          if (a.name == "eindex") {
            a.value = String(nIndex + 1);
            nIndex++;
          } else if (a.name == "name" || a.name == "id") {
            let arr = a.value.split("-");
            arr[arr.length - 1] = nIndex;
            a.value = arr.join("-");
          }
        });

        const changeIndexText = (arr) => {
          arr.forEach((node) => {
            let attrs = node.attrs;

            if (
              node.tag == "span" &&
              attrs.some((a) => a.name == "field" && a.value == "title")
            ) {
              let text = node.children[0];

              if (typeof text == "string") {
                let arr = text.split("、");

                if (arr.length > 1) {
                  arr[0] = nIndex;
                  node.children = [arr.join("、")];
                } else {
                  node.children = [nIndex + "、" + text];
                }
              }
            } else if (node.children && node.children.length > 0) {
              let data = node.children[0];

              data = data.toString();
              if (attrs.length == 2 && data.indexOf("[object Object]") == -1) {
                let title = node.children[0];

                title = title.toString();

                if (nIndex !== 1) {
                  // let res = title.replace(/.*、/, "");
                  let res = title;
                  let arr = res.split("、");

                  let number = Number(arr[0]);
                  console.log(number);
                  if (number !== nIndex) {
                    arr[0] = nIndex;
                  }
                  let newTitle = arr.join("、");
                  // title = nIndex + "、" + res;
                  node.children[0] = newTitle;
                } else {
                  // let res = title.replace(/.*1、/, "");
                  // title = nIndex + "、" + res;
                  node.children[0] = title;
                }
              }

              changeIndexText(node.children);
            }
          });
        };
        changeIndexText(j.children);
      });

      this.editor.txt.setJSON(json);
    },

    /**
     * @description 编辑器内容变化回调函数
     */
    contentChange() {
      let $editor = document.getElementById(`editor${this.sign}`);
      let $titles = $editor.querySelectorAll(".unit-title");

      let arr = [];

      // for (let i = 0; i < $titles.length; i++) {
      //     let $t = $titles[i];
      //     let title = $t.innerText;
      //     arr.push({
      //         index: i,
      //         title,
      //         href: "#material-" + i,
      //     })
      // }

      // this.$set(this, "editorData", arr)
      this.editorUnitNumber = arr.length;

      let json = this.editor.txt.getJSON();

      const circulation = function (arr, obj) {
        arr.forEach((a) => {
          if (a.attrs && a.attrs.length > 0) {
            a.attrs.forEach((attr) => {
              if (attr.name == "field") {
                let key = attr.value;
                let value = a.children[0];
                obj[key] = value;
              }
            });
          }
          if (a.children && !a.children.every((c) => typeof c == "string")) {
            circulation(a.children, obj);
          }
        });
      };

      //let arr = [];
      json.forEach((item, index) => {
        const { children, attrs } = item;
        if (attrs.length > 0 && !attrs.find((a) => a.name == "data-we-empty-p")) {
          let unit = {};
          circulation(children, unit);
          if (Object.keys(unit).length > 0) {
            let title = unit.title;

            if (typeof unit.title == "object") {
              const { eindex } = unit;
              if (eindex && eindex.length > 2) {
                if (eindex.indexOf("、") > 0) {
                  title = unit.eindex.split("、")[1];
                } else if (eindex.indexOf("，") > 0) {
                  title = unit.eindex.split("，")[1];
                } else if (eindex.indexOf(".") > 0) {
                  title = unit.eindex.split(".")[1];
                }
              }
              // else{
              //     title = "【未命名】"
              // }
            }
            if (title && typeof title == "string") {
              if (title.endsWith("：")) {
                title = title.substr(0, title.length - 1);
              }
              arr.push({
                index,
                title,
                href: "#material-" + index,
                //href: "#" + type + "-" + (index+1),
                position: unit.position || "",
              });
            }
          }
        }
      });

      this.$set(this, "editorData", arr);
      this.editorUnitNumber = arr.length;
    },

    /**
     * @description 编辑器键盘事件
     * @param {Object} event 事件对象
     */
    keyUpEvent(event) {
      const { key, ctrlKey } = event;
      if (ctrlKey) {
        if (key == "p") {
          this.addTemplate();
        } else if (key == "s") {
          this.save(true);
        }
      }

      //if( key == "Backspace" ){
      //    this.editor.cmd.do("backwardDelete")
      //}
    },

    /**
     * @description 光标所在模板下方插入模板
     */
    addTemplate() {
      let $unit = null;

      let text = this.editor.txt.text();
      let $editor = document.getElementById(`editor${this.sign}`);
      let $text = $editor.querySelector("div[contenteditable]");

      let textDate = text.replace(/"/g, "&quot;");

      if (text.trim()) {
        //存在内容，直接追加
        let oldJson = this.editor.txt.getJSON();

        oldJson.forEach((oj) => {
          //空的模块处理
          let attrs = oj.attrs;
          if (attrs.some((a) => a.name == "id")) {
            let id = attrs.find((a) => a.name == "id").value;
            let $dom = document.getElementById(id);

            let $titles = $dom.querySelectorAll(".unit-title");

            if ($titles.length > 1) {
              for (let i = 0; i < $titles.length; i++) {
                let $t = $titles[i];
                let innerText = $t.innerText;
                if (
                  innerText.indexOf("、证据") >= 0 ||
                  (innerText.indexOf("、") >= 0 && innerText.indexOf("："))
                ) {
                  let $span = $t.querySelector("[field]");
                  $span && $span.setAttribute("field", "title");
                } else {
                  $t.removeAttribute("field");
                  $t.className = "unit-content";
                  let $span = $t.querySelector("[field='title']");
                  $span && $span.setAttribute("field", "content");
                }
              }
            }
          }
        });

        oldJson = this.editor.txt.getJSON();

        let sourceJson = [];
        oldJson.forEach((oj) => {
          //空的模块处理
          let attrs = oj.attrs;
          if (attrs.some((a) => a.name == "id")) {
            let id = attrs.find((a) => a.name == "id").value;
            let $dom = document.getElementById(id);
            let innerText = $dom.innerText.trim();
            if (innerText && innerText.length > 0) {
              sourceJson.push(oj);
            }
          } else {
            sourceJson.push(oj);
          }
        });

        sourceJson.push(this.getTemplate(sourceJson.length));
        let nIndex = 1;

        const changeIndexText = (arr, parentNode) => {
          for (let i = 0; i < arr.length; i++) {
            let node = arr[i];
            let attrs = node.attrs;
            if (
              node.tag == "span" &&
              attrs.some((a) => a.name == "field" && a.value == "title")
            ) {
              let text = node.children[0];
              if (typeof text == "string") {
                let arr = text.split("、");
                if (arr.length > 1) {
                  arr[0] = nIndex;
                  node.children = [arr.join("、")];
                } else {
                  node.children = [nIndex + "、" + text];
                }
                nIndex++;
              }
            } else if (
              //首行被退格特殊处理
              node.tag == "span" &&
              attrs.some(
                (a) =>
                  a.name == "style" &&
                  a.value.indexOf("font-weight") >= 0 &&
                  a.value.indexOf("600") >= 0 &&
                  a.value.indexOf("font-size") >= 0 &&
                  a.value.indexOf("15px") >= 0
              )
            ) {
              if (
                parentNode &&
                parentNode.attrs.some((a) => a.name == "data-we-empty-p")
              ) {
                let text = node.children[0];
                if (typeof text == "string") {
                  let arr = text.split("、");
                  if (arr.length > 1) {
                    arr[0] = nIndex;
                    node.children = [arr.join("、")];
                  } else {
                    node.children = [nIndex + "、" + text];
                  }
                  nIndex++;
                }
              }
            } else if (node.children && node.children.length > 0) {
              changeIndexText(node.children, node);
            }
          }
        };

        changeIndexText(sourceJson);

        let uIndex = 0;
        sourceJson.forEach((j) => {
          let attrs = j.attrs;
          attrs.forEach((a) => {
            if (a.name == "eindex") {
              a.value = String(uIndex + 1);
              uIndex++;
            } else if (a.name == "name" || a.name == "id") {
              let arr = a.value.split("-");
              arr[arr.length - 1] = uIndex;
              a.value = arr.join("-");
            }
          });
        });

        this.editor.txt.setJSON(sourceJson);
      } else {
        this.editor.txt.setJSON([this.getTemplate(1)]);
      }
      this.resetIndex();
      return false;
    },

    addSpecialTemplate($unit) {
      let eindex = $unit.getAttribute("eindex");
      let newUnit = this.getTemplate(eindex);
      let json = this.editor.txt.getJSON();
      json.splice(eindex, 0, newUnit);
      this.editor.txt.setJSON(json);
      this.resetIndex();
    },

    /**
     * @description 编辑器内容保存方法
     * @param {Boolean} successTip 是否需要保存成功提示
     * @param {Function} callback 保存后的回调函数
     */
    save(successTip = false, callback) {
      let html = this.editor.txt.html();

      html = html.replace(/class="hightlight-text"/gi, "");
      html = html.replace(/class="hightlight-text active"/gi, "");

      try {
        let innerText = document.getElementById(`editor${this.sign}`);
        innerText = innerText.querySelector(".w-e-text");
        innerText = innerText.innerText.trim();
        if (innerText.length == 0) {
          html = "";
        }
      } catch (e) {
        console.log(e);
      }

      const tab = this.facts[this.factActiveIndex];
      const { id: colId } = tab;
      let items = this.editorData.map((d, index) => {
        let str = d.title;

        if (d.position) {
          let p = d.position;
          if (p.startsWith("：")) {
            p = p.substr(1);
          }
          str += `,${p}`;
        }

        return str;
      });

      let params = {
        cid: String(this.cid),
        colId,
        ev: this.activeTabType,
        data: {
          content: html,
          items,
        },
      };

      this.$api.papers.saveContent(params).then(
        (res) => {
          let time = new Date();
          let h = time.getHours();
          let m = time.getMinutes();
          if (String(h).length == 1) {
            h = "0" + h;
          }
          if (String(m).length == 1) {
            m = "0" + m;
          }
          this.$emit("changeSaveTime", `${h}:${m}`);
          if (successTip) {
            this.$message.success("已保存内容");
          }
          callback && callback.call(this);
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },

    /**
     * @description 根据类型获取对应模板json
     * @returns {Object} 模板html节点数据
     */
    getTemplate() {
      const { subheadActiveIndex: index } = this;

      //const {activeTabType:type} = this;
      let secendName = "证据内容";
      if ([2, 3].includes(index)) {
        secendName = "证言内容";
      } else if (index == 4) {
        secendName = "笔录内容";
      }
      let obj = {
        tag: "div",
        attrs: [
          { name: "name", value: "item-unit-0" },
          { name: "id", value: "material-0" },
          { name: "eindex", value: "1" },
          { name: "class", value: "item-unit" },
        ],
        children: [
          {
            tag: "div",
            attrs: [
              { name: "class", value: "unit-title" },
              { name: "field", value: "title" },
            ],
            children: [
              {
                tag: "span",
                attrs: [{ name: "field", value: "title" }],
                children: ["1、证据名称："],
              },
              // {
              //     "tag":"span",
              //     "attrs":[{"name":"field","value":"title"}],
              //     "children":["证据名称"]
              // },
            ],
          },
          {
            tag: "div",
            attrs: [{ name: "class", value: "unit-content" }],
            children: [
              {
                tag: "b",
                attrs: [
                  // {name:"contenteditable",value:false}
                ],
                children: ["案卷位置"],
              },
              {
                tag: "span",
                attrs: [
                  { name: "field", value: "position" },
                  // {"name":"field","value":"content"}
                ],
                children: ["："],
              },
            ],
          },
          {
            tag: "div",
            attrs: [{ name: "class", value: "unit-content" }],
            children: [
              {
                tag: "b",
                attrs: [],
                children: [secendName],
              },
              {
                tag: "span",
                attrs: [{ name: "field", value: "content" }],
                children: ["："],
              },
            ],
          },
          {
            tag: "div",
            attrs: [{ name: "class", value: "unit-content" }],
            children: [
              {
                tag: "b",
                attrs: [],
                children: ["质证意见"],
              },
              {
                tag: "span",
                attrs: [{ name: "field", value: "opinion" }],
                children: ["："],
              },
            ],
          },
        ],
      };
      return obj;
    },

    handleSetJSON() {
      let newUnit = this.getTemplate(1);
      this.editor.txt.setJSON([newUnit]);
    },

    // 自定义文本缩进
    getCustomRetract() {
      const _this = this;
      const { $, DropListMenu } = E;
      // 第一，菜单 class ，Button 菜单继承 BtnMenu class
      // 标题菜单的 class ，可作为 DropList 菜单的参考代码
      class CustomRetract extends DropListMenu {
        constructor(editor) {
          // 菜单栏中，标题菜单的 DOM 元素
          // 注意，这里的 $ 不是 jQuery ，是 E.$ （wangEditor 自带的 DOM 操作工具，类似于 jQuery）
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = $(
            `<div class="w-e-menu" data-title="缩进">
                            <i class="w-e-icon-indent-increase"></i>
                        </div>`
          );
          // droplist 配置
          const dropListConf = {
            width: 130,
            title: "设置缩进",
            type: "list",
            list: [
              {
                $elem: $(
                  `<p>
                                        <i class="w-e-icon-indent-increase w-e-drop-list-item"></i>
                                        增加缩进
                                    <p>`
                ),
                value: "increase",
              },

              {
                $elem: $(
                  `<p>
                                        <i class="w-e-icon-indent-decrease w-e-drop-list-item"></i>
                                        减少缩进
                                    <p>`
                ),
                value: "decrease",
              },
            ],
            clickHandler: (value) => {
              this.command(value);
            },
          };

          super($elem, editor, dropListConf);
        }

        command(value) {
          let _this = this;
          if (value == "increase") {
            _this.indent = _this.indent + 13;
          } else {
            if (_this.indent >= 13) {
              _this.indent = _this.indent - 13;
            }
          }
        }

        // 菜单是否需要激活
        tryChangeActive() {
          const reg = /^h/i;
          const cmdValue = this.editor.cmd.queryCommandValue("formatBlock");
          if (reg.test(cmdValue)) {
            // 选区处于标题内，激活菜单
            this.active();
          } else {
            // 否则，取消激活
            this.unActive();
          }
        }
      }
      return CustomRetract;
    },
    // 自定义行高功能
    getCustomLineHeight() {
      const _this = this;
      const { $, DropListMenu } = E;
      // 第一，菜单 class ，Button 菜单继承 BtnMenu class
      // 标题菜单的 class ，可作为 DropList 菜单的参考代码
      class CustomLineHeight extends DropListMenu {
        constructor(editor) {
          // 菜单栏中，标题菜单的 DOM 元素
          // 注意，这里的 $ 不是 jQuery ，是 E.$ （wangEditor 自带的 DOM 操作工具，类似于 jQuery）
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = $(
            `<div class="w-e-menu" data-title="行高">
                                <i class="w-e-icon-row-height"></i>
                            </div>`
          );
          // droplist 配置
          const dropListConf = {
            width: 100,
            title: "设置行高",
            type: "list",
            list: [
              { $elem: $("<span>1</span>"), value: "1" },
              { $elem: $("<span>1.15</span>"), value: "1.15" },
              { $elem: $("<span>1.6</span>"), value: "1.6" },
              { $elem: $("<span>1.8（默认）</span>"), value: "1.8" },
              { $elem: $("<span>2</span>"), value: "2" },
              { $elem: $("<span>2.5</span>"), value: "2.5" },
            ],
            // droplist 每个 item 的点击事件
            clickHandler: (value) => {
              // value 参数即 dropListConf.list 中配置的 value
              this.command(value);
            },
          };

          super($elem, editor, dropListConf);
        }

        command(value) {
          _this.lineHeight = value;
          // $editor[0].style.lineHeight = value;

          // 设置标题
          // this.editor.cmd.do('formatBlock', value)
        }

        // 菜单是否需要激活
        tryChangeActive() {
          const reg = /^h/i;
          const cmdValue = this.editor.cmd.queryCommandValue("formatBlock");
          if (reg.test(cmdValue)) {
            // 选区处于标题内，激活菜单
            this.active();
          } else {
            // 否则，取消激活
            this.unActive();
          }
        }
      }
      return CustomLineHeight;
    },

    // 自定义文本对齐
    getCustomAlign() {
      const _this = this;
      const { $, DropListMenu } = E;
      // 第一，菜单 class ，Button 菜单继承 BtnMenu class
      // 标题菜单的 class ，可作为 DropList 菜单的参考代码
      class CustomAlign extends DropListMenu {
        constructor(editor) {
          // 菜单栏中，标题菜单的 DOM 元素
          // 注意，这里的 $ 不是 jQuery ，是 E.$ （wangEditor 自带的 DOM 操作工具，类似于 jQuery）
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = $(
            '<div class="w-e-menu" data-title="对齐"><i class="w-e-icon-paragraph-left"></i></div>'
          );

          const dropListConf = {
            width: 100,
            title: "对齐方式",
            type: "list", // droplist 以列表形式展示
            list: [
              {
                $elem: $(
                  `<p>
                      <i class="w-e-icon-paragraph-left w-e-drop-list-item"></i>
                      靠左
                  </p>`
                ),
                value: "left",
              },
              {
                $elem: $(
                  `<p>
                      <i class="w-e-icon-paragraph-center w-e-drop-list-item"></i>
                      居中
                  </p>`
                ),
                value: "center",
              },
              {
                $elem: $(
                  `<p>
                        <i class="w-e-icon-paragraph-right w-e-drop-list-item"></i>
                        靠右
                    </p>`
                ),
                value: "right",
              },
              {
                $elem: $(
                  `<p>
                      <i class="w-e-icon-paragraph-justify w-e-drop-list-item"></i>
                      两端
                  </p>`
                ),
                value: "justify",
              },
            ],
            clickHandler: (value) => {
              // 执行对应的value操作
              this.command(value);
            },
          };
          super($elem, editor, dropListConf);
        }

        command(value) {
          _this.textAlign = value;
        }

        // 菜单是否需要激活
        tryChangeActive() {
          const reg = /^h/i;
          const cmdValue = this.editor.cmd.queryCommandValue("formatBlock");
          if (reg.test(cmdValue)) {
            // 选区处于标题内，激活菜单
            this.active();
          } else {
            // 否则，取消激活
            this.unActive();
          }
        }
      }
      return CustomAlign;
    },

    /**
     * @description 搜索结果定位高亮
     */
    focusHightLight(index) {
      let $editor = document.getElementById(`editor${this.sign}`);
      let $nodes = $editor.querySelectorAll(".hightlight-text");
      this.hightListLength = $nodes.length;
      $nodes.forEach(($n, nindex) => {
        $n.setAttribute("id", "hightLight_" + nindex);
        if (index == nindex) {
          $n.className = "hightlight-text active";
          window.location.href = "#hightLight_" + nindex;
        } else {
          $n.className = "hightlight-text";
        }
      });
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style lang="scss">
.w-e-toolbar {
  background: #f8f8f8;
}

.marking-editor {
  .w-e-toolbar {
    background: #f8f8f8 !important;
    border-color: #dcdfe6 !important;
    z-index: 1 !important;

    .w-e-menu:last-child {
      display: none;
    }

    text-indent: 0 !important;
  }

  .w-e-text-container {
    height: calc(100vh - 245px) !important;

    .placeholder {
      color: #b0b0b0;
    }

    .w-e-text {
      &::-webkit-scrollbar {
        width: 0;
      }

      padding: 18px 16px 52px;
      font-size: 15px;

      .item-unit {
        margin-bottom: 48px;
        border: 1px dotted #c3cfd9;
        padding: 12px;
        pointer-events: none;
        position: relative;
        border-radius: 4px;

        .unit-title {
          font-weight: 600;
          margin-bottom: 6px;
          color: #333333;
          line-height: 24px;
        }

        .unit-content {
          line-height: 32px;
          color: #333333;
          text-align: justify;

          // > span:first-child {
          //     font-weight: 600;
          // }
          &.underline {
            >span {
              text-decoration: underline;
            }
          }
        }

        &:last-child::after {
          content: "按CTRL+P , 在末尾处新增空模版";
          display: block;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #60707d;
          position: absolute;
          bottom: -54px;
          left: 0;
        }

        &::before {
          content: "+";
          pointer-events: auto;
          cursor: pointer;
          position: absolute;
          bottom: -34px;
          left: 0px;
          width: 20px;
          height: 20px;
          background: rgba(64, 185, 164, 0.1);
          border: 1px solid #40b9a4;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          color: #40b9a4;
          transform: scale(1);
        }
      }
    }

    .placeholder {
      padding: 20px;
    }

    .hightlight-text {
      background: rgba(0, 106, 255, 0.1);
      font-weight: bold;

      &.active {
        color: #056bfd;
      }
    }

    i {
      font-style: italic;
    }
  }
}

.editor-content-nav {
  position: absolute;
  top: 121px;
  left: 11px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;

  .editor-content-nav-button {
    width: 30px;
    height: 30px;
    background-size: 100%;
    background: url("~@/assets/image/marking/icon_daohang_default.png") no-repeat;
  }

  &:hover {
    background: #056bfd;

    .editor-content-nav-button {
      background: url("~@/assets/image/marking/icon_daohang_on.png") no-repeat;
    }
  }
}

.editor-reset-index {
  position: absolute;
  top: 161px;
  left: 11px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
}

.editor-nav-popper {
  transform: translateY(-5px);

  .editor-nav-box {
    padding: 20px 15px 5px;
    max-height: 400px;
    overflow: auto;

    .editor-nav-unit {
      margin-bottom: 15px;
      cursor: pointer;
      display: block;
      color: #333333;

      &.active {
        color: #333333;
      }

      &:hover {
        color: #056bfd;
      }
    }
  }
}
</style>

<template>
  <div class="archives_printscreen" v-if="show">
    <vue-cropper
      @mouseenter.native="enter"
      @mouseleave.native="leave"
      ref="cropper"
      :img="uploadImg"
      :outputSize="option.outputSize"
      :outputType="option.outputType"
      :info="true"
      :full="option.full"
      :canMove="option.canMove"
      :canMoveBox="option.canMoveBox"
      :original="option.original"
      :autoCrop="option.autoCrop"
      :fixed="option.fixed"
      :fixedNumber="option.fixedNumber"
      :centerBox="option.centerBox"
      :infoTrue="option.infoTrue"
      :fixedBox="option.fixedBox"
      style="background-image: none"
    ></vue-cropper>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      option: {
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: false, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 5], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMove: false, //时候可以移动原图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        maxImgSize: 1000, // 上传时图片最大大小(默认会压缩尺寸到这个大小)
        original: false, // 上传图片是否按照原始比例渲染
      },
      uploadImg: "",
      show: false,
    };
  },
  methods: {
    tailoring(canvas) {
      console.log("裁剪",canvas);
      let dataURL = canvas.toDataURL("image/png");
      this.uploadImg = dataURL;
      this.show = true;
      this.$nextTick(()=>{
        this.addDBClickEvent();
      })
      // this.$parent.pdfShow = false;
      // if (this.show) {
      //   return;
      // }
      // //裁剪
      // this.$nextTick(() => {
      //   html2canvas(document.getElementById("archives-pdf"), {
      //     useCORS : true
      //   }).then(
      //     (canvas) => {
      //       let dataURL = canvas.toDataURL("image/png");
      //       this.uploadImg = dataURL;
      //       this.show = true;
      //       this.$parent.pdfShow = false;
      //     }
      //   );
      // });
    },
    addDBClickEvent(){
      const $cropper = this.$refs.cropper.$el;
      let $cropBox = $cropper.querySelector(".cropper-face");
      $cropBox.addEventListener("dblclick",()=>{
          this.$emit("cropConfirm")
      })
    },
    enter() {
      if (this.uploadImg == "") {
        return;
      }
      this.$refs.cropper.startCrop(); //开始裁剪
    },
    leave() {
      this.$refs.cropper.stopCrop(); //停止裁剪
    },
    //type为1 普通截图，输出流数据  type为2,识别文字，输出base64
    save(cb,type=2) {
      //确认截图
      if( type == 2 ){
        this.$refs.cropper.getCropData((data) => {
          //获取截图的base64格式数据
          this.show = false;
          cb(data);
        });
      }else{
        this.$refs.cropper.getCropBlob((data) => {
          //获取截图的二进制
          this.show = false;
          cb(data);
        });
      }
      
      
      // this.$refs.cropper.getCropBlob(data => { //获取截图的Blob格式数据
      //   this.cutImg = data;
      // });
    },
    close() {
      //取消
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.archives_printscreen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 32px;
  // top:0;
  left: 0;
  // border: 1px dashed red;
  border:none;
  background: rgba(0, 0, 0, 0.5);
}
</style>
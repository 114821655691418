import { render, staticRenderFns } from "./editor-item.vue?vue&type=template&id=260fe50c"
import script from "./editor-item.vue?vue&type=script&lang=js"
export * from "./editor-item.vue?vue&type=script&lang=js"
import style0 from "./editor-item.vue?vue&type=style&index=0&id=260fe50c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports